import React from 'react'
import Modal from 'react-modal'
import { ErrorBoundary } from 'react-error-boundary'
import { BrowserRouter as Router } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { AppProvider } from './hooks'
import { Routes } from './routes'
import GlobalStyle from './styles/global'
import 'react-toastify/dist/ReactToastify.min.css'
import { MyFallbackComponent } from './error/ErrorBoundary'
import { AgentView } from './AgentPages/AgentView'

export function App() {
    Modal.setAppElement('#root')

    return (
        <AppProvider>
            <Router>
                <ErrorBoundary
                    FallbackComponent={MyFallbackComponent}
                    onReset={() => {
                        // reset the state of your app here
                    }}
                    resetKeys={['someKey']}
                >
                    <AgentView>
                        <Routes />
                    </AgentView>
                    <ToastContainer newestOnTop autoClose={2000} />
                </ErrorBoundary>
            </Router>
            <GlobalStyle />
        </AppProvider>
    )
}
