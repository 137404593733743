import React from 'react'
import { Switch } from 'react-router-dom'
import { ConfigurationPage } from '../pages/Configuration'
import { HomePage } from '../pages/Home'
import { LoginPage } from '../pages/Login'
import { PoliticsPage } from '../pages/Politics'
import { ReportsPage } from '../pages/Reports'
import { UserInfoPage } from '../pages/UserInfo/UserInfo'
import { UsersPage } from '../pages/Users'
import { Route } from './route'
import FinancialPage from '../pages/Financial'
import { NoUser } from '../pages/NoUser'

export function Routes() {
    return (
        <Switch>
            <Route path="/" isPrivate={false} exact component={LoginPage} />
            <Route path="/no-user" isPrivate exact component={NoUser} />
            <Route path="/home" isPrivate exact component={HomePage} />
            <Route
                path="/configurations"
                isPrivate
                exact
                component={ConfigurationPage}
            />
            <Route path="/users" isPrivate exact component={UsersPage} />
            <Route path="/politics" isPrivate exact component={PoliticsPage} />
            <Route path="/user-info" isPrivate exact component={UserInfoPage} />
            <Route path="/reports" isPrivate exact component={ReportsPage} />
            <Route path="/expenses" isPrivate exact component={FinancialPage} />
        </Switch>
    )
}
