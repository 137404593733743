import styled from 'styled-components'

export const SearchForUserContainer = styled.div`
    margin-top: 5.6rem;
    .search-all-container {
        position: relative;
        width: 100%;
        height: 80vh;
        .users-container {
            overflow-y: auto;
            position: absolute;
            top: 7rem;
            background-color: #fff;
            max-height: 50rem;
            padding: 2rem 0;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            border-radius: 0.8rem;
            box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.6);
        }
    }
`
export const UserButton = styled.button`
    border: none;
    background-color: transparent;
    width: 100%;
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    transition: background-color 0.2s linear;
    .avatar {
        width: 4rem;
        margin-right: 1rem;
    }
    .avatar-icon {
        margin-right: 1.3rem;
        margin-left: 1rem;
        color: var(--color-primary);
    }
    .user-info {
        width: calc(100% - 5rem);
        .name {
            text-align: start;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .company {
            text-align: start;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    &:hover {
        background-color: #f4f4f4;
    }
`
export const CompanyButton = styled.button`
    position: relative;
    margin-bottom: 1rem;
    border: none;
    border-radius: 0.8rem;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.6);
    background-color: #fff;
    width: 100%;
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    transition: background-color 0.2s linear;
    &::before {
        content: 'X';
        position: absolute;
        right: 1.8rem;
        top: 1rem;
    }
    .avatar {
        width: 4rem;
        margin-right: 1rem;
    }
    .avatar-icon {
        margin-right: 1.3rem;
        margin-left: 1rem;
        color: var(--color-primary);
    }
    .user-info {
        width: calc(100% - 5rem);
        .name {
            text-align: start;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .company {
            text-align: start;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    &:hover {
        background-color: #f4f4f4;
    }
`
