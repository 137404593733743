/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-param-reassign */
import React, { createContext, ReactNode, useContext, useState } from 'react'
import { toast } from 'react-toastify'
import { EditSupplierCredential } from '../dtos/edit-supplier-credential'
import { ActiveSupplier, CredentialSupplier } from '../entities/active-supplier'
import { Company } from '../entities/company'
import { FullManagementInfo } from '../entities/full-management-info'
import { ManagementInfo } from '../entities/management-info'
import { ManagementInfoOption } from '../entities/management-info-option'
import { User } from '../entities/user'
import { Address } from '../interfaces/address'
import { CompanyContact } from '../interfaces/company-contact'
import { Supplier } from '../interfaces/supplier'
import { TravelerClearance } from '../interfaces/traveler-clearance/traveler-clearance/traveler-clearance'
import { api } from '../service/api'
import { useAuth } from './use-auth'
import { EditMemberDto } from '../dtos/edit-member'
import { Invite } from '../entities/invite'

interface CompanyContext {
    getCompanyProfile(id: string): Promise<Company | undefined>
    getMembers(id: string): Promise<User[] | undefined>
    filterMembers({
        name,
        travelerClearanceId,
    }: {
        name: string | undefined
        travelerClearanceId: string | undefined
    }): void
    createOffice(data: Address): Promise<Address | undefined>
    getMember(id: string): Promise<User | undefined>
    addManagementInfo(label: string): Promise<ManagementInfo | undefined>
    editManagementInfo(
        managementInfoId: string,
        isMandatory: boolean
    ): Promise<ManagementInfo | undefined>
    addContactInfo(data: CompanyContact): Promise<CompanyContact | undefined>
    removeContactInfo(index: number): Promise<CompanyContact | undefined>
    editMember(editMemberData: EditMemberDto): Promise<User | undefined>
    editMaxApprovalLevel(num: number): Promise<any | undefined>
    addManagementInfoOption(
        managementId: string,
        data: { label: string; externalId: string; userIds: string[] }
    ): Promise<{ label: string; externalId: string; id: string } | undefined>
    editPricePerKilometer(num: number): Promise<any | undefined>
    editOption(
        managementId: string,
        optionId: string,
        data: {
            externalId: string
            label: string
            userIds: string[]
        }
    ): Promise<
        | {
              externalId: string
              label: string
              users: User[]
          }
        | undefined
    >
    getManagementInfo(): Promise<FullManagementInfo[] | undefined>
    userIdsToAddInOptionHandler(data: string[]): void
    openEditOptionHandler(data: ManagementInfoOption | undefined): void
    createTravelerClearance(
        label: string
    ): Promise<TravelerClearance | undefined>
    chooseTravelerClearanceToBeSelected(
        travelerClearance: TravelerClearance
    ): void
    editTravelerClearance(id: string, data: any): Promise<any | undefined>
    getSuppliers(): Promise<Supplier[] | undefined>
    getActiveSupliers(): Promise<ActiveSupplier[] | undefined>
    editSupplierStatus(id: string, type: 'disable' | 'enable'): Promise<any>
    exportUsers(): Promise<any>
    exportManagementInfos(): Promise<any>
    importUsers(formData: FormData): Promise<boolean | undefined>
    importOptions(
        formData: FormData,
        managementInfoId: string
    ): Promise<boolean | undefined>
    importInviteUsers(formData: FormData): Promise<boolean | undefined>
    companyManagementInfos: Company['managementInfo']
    editSupplierCredential(data: {
        id: string
        credentialId: string
        data: EditSupplierCredential
    }): Promise<CredentialSupplier | undefined>
    createSupplierCredential({
        id,
        hash,
    }: {
        id: string
        hash: string
    }): Promise<CredentialSupplier | undefined>
    getActivePromoCode(id: string): Promise<{ valid: boolean } | undefined>
    getInvites(companyId: string): Promise<any[] | undefined>
    getDecodedCredential(credentialId: string): Promise<any | undefined>
    decodedCredential: { [key: string]: any } | undefined
    invites: Invite[]
    company?: Company
    members: User[]
    member?: User
    managementInfo: FullManagementInfo[]
    userIdsToAddInOption: string[]
    openEditOption: ManagementInfoOption | undefined
    travelerClearances: TravelerClearance[]
    selectTravelerClearance?: TravelerClearance
    suppliers: Supplier[]
    activatedSuppliers: ActiveSupplier[]
    getAllCompanies(query: string): Promise<Company[] | undefined>
    companies: Company[]
}

interface Props {
    children: ReactNode
}

const CompanyContext = createContext({} as CompanyContext)

export function CompanyProvider({ children }: Props) {
    // hooks
    const { token } = useAuth()
    // state
    const [company, setCompany] = useState<Company>()
    const [companyManagementInfos, setCompanyManagementInfos] = useState<
        Company['managementInfo']
    >([])

    const [members, setMembers] = useState<User[]>([])
    const [membersBackup, setMembersBackup] = useState<User[]>([])
    const [userIdsToAddInOption, setUserIdsToAddInOption] = useState<string[]>(
        []
    )
    const [member, setMember] = useState<User>()
    const [managementInfo, setManagementInfo] = useState<FullManagementInfo[]>(
        []
    )
    const [openEditOption, setOpenEditOption] = useState<
        ManagementInfoOption | undefined
    >()
    const [travelerClearances, setTravelerClearances] = useState<
        TravelerClearance[]
    >([])
    const [selectTravelerClearance, setSelectTravelerClearance] =
        useState<TravelerClearance>()
    const [suppliers, setSuppliers] = useState<Supplier[]>([])
    const [activatedSuppliers, setActivatedSuppliers] = useState<
        ActiveSupplier[]
    >([])
    const [invites, setInvites] = useState<Invite[]>([])
    const [decodedCredential, setDecodedCredential] = useState<
        { [key: string]: any } | undefined
    >()
    const [companies, setCompanies] = useState<Company[]>([])

    function openEditOptionHandler(data: ManagementInfoOption | undefined) {
        setOpenEditOption(data)
    }

    function userIdsToAddInOptionHandler(data: string[]) {
        setUserIdsToAddInOption(data)
    }

    function chooseTravelerClearanceToBeSelected(
        travelerClearance: TravelerClearance
    ) {
        setSelectTravelerClearance(travelerClearance)
    }

    async function getCompanyProfile(id: string) {
        try {
            const response = await api.get<Company>(`/companies/${id}`, {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            })
            setCompany(response.data)
            if (response.data.managementInfo) {
                setCompanyManagementInfos(response.data.managementInfo)
            }
            if (response.data.travelerClearances) {
                setTravelerClearances(response.data.travelerClearances)
                chooseTravelerClearanceToBeSelected(
                    response.data.travelerClearances[0]
                )
            }
            return response.data
        } catch (error: any) {
            const e: string = error.response.data.details.pt
            toast.warning(e)
            return undefined
        }
    }

    async function createTravelerClearance(label: string) {
        try {
            if (company) {
                const newTravelerClereance: Omit<TravelerClearance, 'id'> = {
                    label,
                    policies: travelerClearances.find(
                        (clearance) => clearance.label === 'Padrão'
                    )!.policies,
                }

                const response = await api.post<TravelerClearance>(
                    `companies/${company?.id}/travelerClearance`,
                    newTravelerClereance,
                    { headers: { authorization: `Bearer ${token}` } }
                )
                setTravelerClearances((prevState) => [
                    ...prevState,
                    response.data,
                ])
                chooseTravelerClearanceToBeSelected(response.data)
                return response.data
            }
            return undefined
        } catch (error: any) {
            if (
                error.response.data.details.body.details[0].type === 'any.only'
            ) {
                toast.warning(
                    'Já existe uma política cadastrada com esse nome.'
                )
            }
            return undefined
        }
    }

    async function editTravelerClearance(id: string, data: any) {
        try {
            if (company) {
                const policies = { policies: data }
                const response = await api.patch(
                    `companies/${company?.id}/travelerClearance/${id}/polices`,
                    policies,
                    { headers: { authorization: `Bearer ${token}` } }
                )
                if (response.data) {
                    toast.success('Política editada com sucesso!')
                }
                return response.data
            }
            return undefined
        } catch (error: any) {
            const e: string = error.response.data.details.pt
            toast.warning(e)
            return undefined
        }
    }

    async function getMembers(id: string) {
        try {
            const response = await api.get<User[]>(`/companies/${id}/members`, {
                headers: { authorization: `Bearer ${token}` },
            })
            setMembers(response.data)
            setMembersBackup([...response.data])
            return response.data
        } catch (error: any) {
            const e: string = error.response.data.details.pt
            toast.warning(e)
            return undefined
        }
    }

    async function editMember(editMemberData: EditMemberDto) {
        try {
            const response = await api.patch<User | undefined>(
                `/companies/${company?.id}/members/${member?.id}`,
                editMemberData,
                { headers: { authorization: `Bearer ${token}` } }
            )
            if (response.data) {
                toast.success(
                    `Viajante ${member?.firstName} ${member?.lastName} editado com sucesso`
                )

                setMembers((prevState) => {
                    const index = prevState.findIndex(
                        (m) => m.id === response.data!.id
                    )
                    if (index !== undefined && response.data) {
                        prevState[index] = {
                            ...response.data,
                            billingInfo: response.data.billingInfo,
                            companyInfo: response.data.companyInfo.map(
                                (cInfo) => {
                                    return {
                                        userIsActive:
                                            editMemberData.userIsActive ||
                                            false,
                                        id: cInfo.id,
                                        managementInfo: [
                                            ...cInfo.managementInfo,
                                        ],
                                        permissions: {
                                            approvesFor: {
                                                ...cInfo.permissions
                                                    .approvesFor,
                                                managementInfo: [
                                                    ...cInfo.permissions
                                                        .approvesFor
                                                        .managementInfo,
                                                ],
                                                usersIds: [
                                                    ...cInfo.permissions
                                                        .approvesFor.usersIds,
                                                ],
                                            },
                                            requestsFor: {
                                                ...cInfo.permissions
                                                    .requestsFor,
                                                managementInfo: [
                                                    ...cInfo.permissions
                                                        .requestsFor
                                                        .managementInfo,
                                                ],
                                                usersIds: [
                                                    ...cInfo.permissions
                                                        .requestsFor.usersIds,
                                                ],
                                            },
                                        },
                                    }
                                }
                            ),
                        } as User
                        return prevState
                    }
                    return prevState
                })
                await getMembers(company!.id)
            }
            return response.data
        } catch (error: any) {
            const e: string = error.response.data.details.pt
            toast.warning(e)
            return undefined
        }
    }

    function filterMembers(filter: {
        name?: string
        travelerClearanceId?: string
    }) {
        let filteredMembers = membersBackup.slice() // Make a shallow copy to avoid mutating the original array
        if (filter.name) {
            filteredMembers = filteredMembers.filter((m) => {
                const name = `${m.firstName} ${m.lastName}`.toLocaleLowerCase()
                return name.includes(filter.name?.toLocaleLowerCase() || '')
            })
        }
        if (filter.travelerClearanceId) {
            filteredMembers = filteredMembers.filter((m) => {
                return (
                    m.companyInfo[0]?.travelerClearanceId ===
                    filter.travelerClearanceId
                )
            })
        }
        setMembers(filteredMembers)
    }
    async function getMember(id: string) {
        try {
            if (company) {
                const response = await api.get<User>(
                    `/companies/${company.id}/members/${id}`,
                    {
                        headers: { authorization: `Bearer ${token}` },
                    }
                )
                setMember(response.data)
                return response.data
            }
            return undefined
        } catch (error: any) {
            const e: string = error.response.data.details.pt
            toast.warning(e)
            return undefined
        }
    }

    async function createOffice(data: Address) {
        try {
            if (company) {
                const response = await api.post<Address>(
                    `/companies/${company.id}/office-addresses`,
                    data,
                    { headers: { authorization: `Bearer ${token}` } }
                )

                setCompany((prevState) => {
                    if (prevState) {
                        return {
                            ...prevState,
                            officeAddresses: [
                                ...prevState.officeAddresses,
                                response.data,
                            ],
                        }
                    }
                    return undefined
                })
                return response.data
            }
            return undefined
        } catch (error: any) {
            const e: string = error.response.data.details.pt
            toast.warning(e)
            return undefined
        }
    }

    async function addManagementInfo(label: string) {
        try {
            if (company) {
                const response = await api.post<ManagementInfo>(
                    `/companies/${company?.id}/management-info`,
                    { label },
                    { headers: { authorization: `Bearer ${token}` } }
                )
                if (response.data) {
                    setCompany((prevState) => {
                        if (prevState) {
                            return {
                                ...prevState,
                                managementInfo: [
                                    ...(prevState?.managementInfo || []),
                                    response.data,
                                ],
                            }
                        }
                        return undefined
                    })
                    setManagementInfo((prevState) => {
                        return [...prevState, response.data]
                    })
                    return response.data
                }
                return undefined
            }
            return undefined
        } catch (error: any) {
            const e: string = error.response.data.details.pt
            toast.warning(e)
            return undefined
        }
    }

    async function getManagementInfo() {
        try {
            if (company) {
                const response = await api.get<FullManagementInfo[]>(
                    `/companies/${company?.id}/management-info`,
                    { headers: { authorization: `Bearer ${token}` } }
                )
                if (response.data) {
                    setManagementInfo(response.data)
                }
                return response.data
            }
            return undefined
        } catch (error: any) {
            const e: string = error.response.data.details.pt
            toast.warning(e)
            return undefined
        }
    }

    async function editOption(
        managementId: string,
        optionId: string,
        data: {
            externalId: string
            label: string
            userIds: string[]
        }
    ) {
        try {
            if (company) {
                const response = await api.patch<{
                    externalId: string
                    label: string
                    users: User[]
                }>(
                    `/companies/${company?.id}/management-info/${managementId}/options/${optionId}`,
                    data,
                    { headers: { authorization: `Bearer ${token}` } }
                )
                if (response.data) {
                    setManagementInfo((prevState) => {
                        const index = prevState.findIndex(
                            (m) => m.id === managementId
                        )
                        if (index !== undefined) {
                            const indexOption = prevState[
                                index
                            ].options.findIndex((o) => o.id === optionId)
                            if (indexOption !== undefined) {
                                prevState[index].options[indexOption] = {
                                    id: optionId,
                                    externalId: response.data.externalId,
                                    label: response.data.label,
                                    users:
                                        response.data.users.length > 0
                                            ? response.data.users.map(
                                                  (user) => {
                                                      return {
                                                          firstName:
                                                              user.firstName,
                                                          id: user.id,
                                                          lastName:
                                                              user.lastName,
                                                      }
                                                  }
                                              )
                                            : [],
                                }
                            }
                        }
                        return prevState
                    })
                    return response.data
                }
                return undefined
            }
            return undefined
        } catch (error: any) {
            const e: string = error.response.data.details.pt
            toast.warning(e)
            return undefined
        }
    }

    async function addManagementInfoOption(
        managementId: string,
        data: { label: string; externalId: string; userIds: string[] }
    ) {
        try {
            if (company) {
                const response = await api.post<{
                    label: string
                    externalId: string
                    id: string
                    users: []
                }>(
                    `/companies/${company?.id}/management-info/${managementId}/options`,
                    data,
                    { headers: { authorization: `Bearer ${token}` } }
                )
                if (response.data) {
                    setManagementInfo((prevState) => {
                        if (prevState) {
                            prevState
                                .find((m) => m.id === managementId)
                                ?.options.push({ ...response.data, users: [] })
                            return [...prevState]
                        }
                        return []
                    })
                    return response.data
                }
                return undefined
            }
            return undefined
        } catch (error: any) {
            const e: string = error.response.data.details.pt
            toast.warning(e)
            return undefined
        }
    }

    async function addContactInfo(data: CompanyContact) {
        try {
            if (company) {
                const response = await api.post<CompanyContact>(
                    `/companies/${company?.id}/contacts`,
                    data,
                    { headers: { authorization: `Bearer ${token}` } }
                )
                if (response.data) {
                    setCompany((prevState) => {
                        if (prevState) {
                            return {
                                ...prevState,
                                contactInfo: [
                                    ...(prevState?.contactInfo || []),
                                    response.data,
                                ],
                            }
                        }
                        return undefined
                    })
                    return response.data
                }
                return undefined
            }
            return undefined
        } catch (error: any) {
            const e: string = error.response.data.details.pt
            toast.warning(e)
            return undefined
        }
    }

    async function removeContactInfo(index: number) {
        try {
            if (company) {
                const response = await api.delete<CompanyContact>(
                    `/companies/${company?.id}/contacts/${index}`,
                    { headers: { authorization: `Bearer ${token}` } }
                )
                if (response.status === 204) {
                    const contactInfos = company.contactInfo
                    contactInfos.splice(index, 1)
                    setCompany((prevState) => {
                        if (prevState) {
                            return {
                                ...prevState,
                                contactInfo: [...contactInfos],
                            }
                        }
                        return prevState
                    })
                    toast.success('Contato removido com sucesso.')
                    return response.data
                }
            }
            return undefined
        } catch (error: any) {
            const e: string = error.response.data.details.pt
            toast.warning(e)
            return undefined
        }
    }

    async function getSuppliers() {
        try {
            const response = await api.get<Supplier[]>(`/suppliers`, {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            })
            setSuppliers([...response.data])
            return response.data
        } catch (error: any) {
            const e: string = error.response.data.details.pt
            toast.warning(e)
            return undefined
        }
    }

    async function getActiveSupliers() {
        try {
            const response = await api.get<ActiveSupplier[]>(
                `companies/${company!.id}/active-suppliers`,
                {
                    headers: {
                        authorization: `Bearer ${token}`,
                    },
                }
            )
            setActivatedSuppliers([...response.data])
            return response.data
        } catch (error: any) {
            const e: string = error.response.data.details.pt
            toast.warning(e)
            return undefined
        }
    }

    async function getDecodedCredential(credentialId: string) {
        try {
            const response = await api.get<ActiveSupplier[]>(
                `companies/${company!.id}/credentials/${credentialId}`,
                {
                    headers: {
                        authorization: `Bearer ${token}`,
                    },
                }
            )
            setDecodedCredential(response.data)
            return response.data
        } catch (error: any) {
            const e: string = error.response.data.details.pt
            toast.warning(e)
            return undefined
        }
    }

    async function editSupplierStatus(id: string, type: 'disable' | 'enable') {
        try {
            const response = await api.put<ActiveSupplier[]>(
                `companies/${company!.id}/suppliers/${id}/${type}`,
                {},
                {
                    headers: {
                        authorization: `Bearer ${token}`,
                    },
                }
            )
            toast.success(
                `Fornecedor ${
                    type === 'disable' ? 'desabilitado' : 'habilitado'
                } com sucesso`
            )
            setActivatedSuppliers([...response.data])
            return response.data
        } catch (error: any) {
            const e: string = error.response.data.details.pt
            toast.warning(e)
            return undefined
        }
    }

    async function editSupplierCredential({
        id,
        credentialId,
        data,
    }: {
        id: string
        credentialId: string
        data: EditSupplierCredential
    }) {
        try {
            const response = await api.put<CredentialSupplier>(
                `companies/${
                    company!.id
                }/suppliers/${id}/credential/${credentialId}`,
                data,
                {
                    headers: {
                        authorization: `Bearer ${token}`,
                    },
                }
            )
            toast.success(`Credencial editada com sucesso`)
            setActivatedSuppliers((prevState) => {
                const index = activatedSuppliers.findIndex(
                    (s) => s.supplierId === id
                )
                if (index !== undefined) {
                    prevState[index].credential = response.data
                    return [...prevState]
                }
                return prevState
            })
            return response.data
        } catch (error: any) {
            const e: string = error.response.data.details.pt
            toast.warning(e)
            return undefined
        }
    }

    async function createSupplierCredential({
        id,
        hash,
    }: {
        id: string
        hash: string
    }) {
        try {
            const response = await api.post<CredentialSupplier>(
                `companies/${company!.id}/suppliers/${id}/credential`,
                { hash },
                {
                    headers: {
                        authorization: `Bearer ${token}`,
                    },
                }
            )
            toast.success(`Credencial editada com sucesso`)
            setActivatedSuppliers((prevState) => {
                const index = activatedSuppliers.findIndex(
                    (s) => s.supplierId === id
                )
                if (index !== undefined) {
                    prevState[index].credential = response.data
                    return [...prevState]
                }
                return prevState
            })
            return response.data
        } catch (error: any) {
            const e: string = error.response.data.details.pt
            toast.warning(e)
            return undefined
        }
    }

    async function editPricePerKilometer(num: number) {
        try {
            await api.patch<any>(
                `companies/${company!.id}/price-per-kilometer`,
                { pricePerKilometer: num },
                {
                    headers: {
                        authorization: `Bearer ${token}`,
                    },
                }
            )
            toast.success(`Preço por quilometragem editado com sucesso`)
            // @ts-ignore
            setCompany((prev) => {
                return {
                    ...prev,
                    pricePerKilometer: num,
                }
            })
            return true
        } catch (error: any) {
            const e: string = error.response.data.details.pt
            toast.warning(e)
            return undefined
        }
    }

    async function editMaxApprovalLevel(num: number) {
        try {
            await api.patch<any>(
                `companies/${company!.id}/max-approval-level`,
                { maxApprovalLevel: num },
                {
                    headers: {
                        authorization: `Bearer ${token}`,
                    },
                }
            )
            toast.success(`Nível de aprovação máximo alterado com sucesso.`)
            // @ts-ignore
            setCompany((prev) => {
                return {
                    ...prev,
                    maxApprovalLevel: num,
                }
            })
            return true
        } catch (error: any) {
            const e: string = error.response.data.details.pt
            toast.warning(e)
            return undefined
        }
    }

    async function exportUsers() {
        try {
            const response = await api.get(
                `companies/${company!.id}/members/csv`,
                {
                    headers: { authorization: `Bearer ${token}` },
                }
            )
            window.open(response.data)

            return response.data
        } catch (error: any) {
            const e: string = error.response.data.details.pt
            toast.warning(e)
            return undefined
        }
    }

    async function exportManagementInfos() {
        try {
            const response = await api.get(
                `companies/${company!.id}/management-info/csv`,
                {
                    headers: { authorization: `Bearer ${token}` },
                }
            )
            window.open(response.data)

            return response.data
        } catch (error: any) {
            const e: string = error.response.data.details.pt
            toast.warning(e)
            return undefined
        }
    }

    async function importUsers(formData: FormData) {
        try {
            await api.patch(
                `companies/${company!.id}/members/batch`,
                formData,
                {
                    headers: {
                        authorization: `Bearer ${token}`,
                    },
                }
            )
            return true
        } catch (error: any) {
            const e: string = error.response.data.details.pt
            toast.warning(e)
            return undefined
        }
    }

    async function importInviteUsers(formData: FormData) {
        try {
            await api.post(`companies/${company!.id}/invites/batch`, formData, {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            })
            return true
        } catch (error: any) {
            const e: string = error.response.data.details.pt
            toast.warning(e)
            return undefined
        }
    }

    async function importOptions(formData: FormData, managementInfoId: string) {
        try {
            await api.post(
                `companies/${
                    company!.id
                }/management-info/${managementInfoId}/options/batch`,
                formData,
                {
                    headers: {
                        authorization: `Bearer ${token}`,
                    },
                }
            )
            return true
        } catch (error: any) {
            const e: string = error.response.data.details.pt
            toast.warning(e)
            return undefined
        }
    }

    async function editManagementInfo(
        managementInfoId: string,
        isMandatory: boolean
    ) {
        try {
            const res = await api.patch(
                `companies/${company!.id}/management-info/${managementInfoId}`,
                { isMandatory },
                {
                    headers: {
                        authorization: `Bearer ${token}`,
                    },
                }
            )
            setManagementInfo((prevState) => {
                if (prevState) {
                    const index = prevState.findIndex(
                        (m) => m.id === managementInfoId
                    )
                    if (index !== undefined) {
                        prevState[index].isMandatory = isMandatory
                    }
                }
                return [...prevState]
            })
            return res.data
        } catch (error: any) {
            const e: string = error.response.data.details.pt
            toast.warning(e)
            return undefined
        }
    }

    async function getActivePromoCode(id: string) {
        try {
            const response = await api.get<{ valid: boolean }>(
                `companies/${
                    company!.id
                }/credentials/${id}/validate-promo-code`,
                {
                    headers: {
                        authorization: `Bearer ${token}`,
                    },
                }
            )
            return response.data
        } catch (error: any) {
            const e: string = error.response.data.details.pt
            toast.warning(e)
            return undefined
        }
    }

    async function getInvites(companyId: string) {
        try {
            const response = await api.get<Invite[]>(
                `/companies/${companyId}/invites`,
                {
                    headers: {
                        authorization: `Bearer ${token}`,
                    },
                }
            )
            setInvites(response.data)
            return response.data
        } catch (error: any) {
            const e: string = error.response.data.details.pt
            toast.warning(e)
            return undefined
        }
    }

    async function getAllCompanies(query: string) {
        try {
            const response = await api.get<Company[]>('/companies', {
                params: {
                    query,
                },
                headers: { authorization: `Bearer ${token}` },
            })

            setCompanies([...response.data])
            return response.data
        } catch (error: any) {
            toast.warning(error.response.data.errorCode)
            return undefined
        }
    }

    return (
        <CompanyContext.Provider
            value={{
                getCompanyProfile,
                getMember,
                company,
                members,
                getMembers,
                getActivePromoCode,
                exportUsers,
                createOffice,
                member,
                addManagementInfo,
                addContactInfo,
                addManagementInfoOption,
                editOption,
                getManagementInfo,
                exportManagementInfos,
                importInviteUsers,
                importOptions,
                editManagementInfo,
                managementInfo,
                userIdsToAddInOption,
                editMaxApprovalLevel,
                userIdsToAddInOptionHandler,
                openEditOption,
                openEditOptionHandler,
                travelerClearances,
                createTravelerClearance,
                selectTravelerClearance,
                importUsers,
                chooseTravelerClearanceToBeSelected,
                editTravelerClearance,
                editSupplierCredential,
                createSupplierCredential,
                getSuppliers,
                suppliers,
                getActiveSupliers,
                activatedSuppliers,
                editSupplierStatus,
                filterMembers,
                editPricePerKilometer,
                removeContactInfo,
                editMember,
                companyManagementInfos,
                getInvites,
                invites,
                decodedCredential,
                getDecodedCredential,
                getAllCompanies,
                companies,
            }}
        >
            {children}
        </CompanyContext.Provider>
    )
}

export function useCompany(): CompanyContext {
    const context = useContext(CompanyContext)
    if (!context) {
        throw new Error('useCompany must be within a Provider')
    }
    return context
}
