import styled from 'styled-components'

export const AgentViewContainer = styled.div`
    overflow: hidden;
    width: 100vw;
    .search-for-user {
        margin-top: 5.6rem;
    }
    .agent-header {
        position: fixed;
        z-index: 1000;
        width: 100%;
        height: 5.6rem;
        padding: 0 2.3rem;
        background: #ffffff;
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.15);
        display: flex;
        align-items: center;
        justify-content: space-between;
        .logo {
            display: flex;
            align-items: flex-start;
            img {
                margin-right: 1rem;
            }
        }
        .menu-user {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin: 0 1rem 0 1.3rem;

            .user-info {
                position: relative;
                margin: 0 1rem;
                .user-name-label {
                    font-size: 1.6rem;
                    font-weight: 700;
                }
                .user-company-label {
                    font-size: 1.4rem;
                    color: var(--color-gray);
                    font-weight: 500;
                }
            }
            .dropdown-btn {
                border: none;
                background-color: inherit;
                outline: none;
                svg {
                    width: 2rem;
                    height: 2rem;
                }
            }
        }
        .menu-user-nav {
            width: 16em;
            position: absolute;
            bottom: 0;
            right: 0rem;
            transform: translateY(100%);
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            background-color: var(--color-light);
            box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.15);
            a {
                text-decoration: none;
            }
            z-index: 50;
            .menu-user-nav--btn {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                border: none;
                background-color: inherit;
                padding: 2rem;
                border-bottom: 1px solid var(--color-background);
                svg {
                    margin-right: 1rem;
                }
            }
        }
        .menu-user-nav-opaciy {
            position: absolute;
            opacity: 0.2;
            width: 100%;
            height: 100vh;
        }
    }
    .body {
        width: 100%;
        overflow-x: scroll;
        display: grid;
        grid-template-columns: 35rem 1fr 35rem;
        .center {
            margin-top: 8.6rem;
            .children {
                position: relative;
                width: 100%;
                box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.15);
                .children-header {
                    height: 4.8rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    h1 {
                        color: #ffffff;
                        font-size: 1.8rem;
                        font-weight: 700;
                    }
                    svg {
                        color: #ffffff;
                        margin-right: 1rem;
                    }
                }
                .bg-gray {
                    background-color: #646669;
                }
                .bg-red {
                    background-color: red;
                }
                max-height: 90vh !important;

                min-width: 100%;
                overflow-y: scroll;
            }
        }
        .left {
            padding: 3rem;
            position: relative;
            min-width: 30rem;
        }
        .right {
            position: relative;
            padding: 3rem;
            min-width: 30rem;
        }
    }
`

export const UserTag = styled.div``
