import styled from 'styled-components'

export const Container = styled.div`
    width: 100vw;
    background-color: red;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 6rem; /* mt-24 */
`

// Styled component for the heading
export const Heading = styled.h1`
    font-size: 2.25rem; /* text-4xl */
    font-weight: bold;
    margin-bottom: 6rem; /* mb-24 */
`
