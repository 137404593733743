import React, { createContext, useContext, useState, ReactNode } from 'react'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'
import { CreateInvite } from '../dtos/create-invite'
import { LoginDto } from '../dtos/login'
import { api } from '../service/api'

interface AuthContext {
    token?: string
    createInvite(data: CreateInvite): Promise<any | undefined>
    login(data: LoginDto): Promise<number | unknown>
    logout(): void
    loginWithToken(tokenData: string): void
    logoutAgent(): void
}

interface Props {
    children: ReactNode
}

const AuthContext = createContext({} as AuthContext)

export function AuthProvider({ children }: Props) {
    const history = useHistory()

    // State
    const [token, setToken] = useState<string | undefined>(() => {
        return (
            localStorage.getItem('@ada_admin_travel_agent:token') || undefined
        )
    })

    async function login(data: LoginDto) {
        try {
            const signupData: LoginDto = {
                email: data.email.toLowerCase(),
                password: data.password,
            }
            const response = await api.post<{ token: string }>(
                '/auth',
                signupData
            )
            localStorage.setItem(
                '@ada_admin_travel_agent:token',
                response.data.token
            )
            setToken(response.data.token)
            return response.status
        } catch (error: any) {
            const e: string = error.response.data.details.pt
            toast.warning(e)
            return error.response.data.errorCode
        }
    }

    function logoutAgent() {
        setToken(undefined)
        window.location.reload()
        localStorage.removeItem('@ada_admin_travel_agent:token')
        localStorage.removeItem('@ada:token')
        localStorage.removeItem('@ada:internetionalSearch')
        localStorage.removeItem('@ada:departureLocation')
        localStorage.removeItem('@ada:arrivalLocation')
        localStorage.removeItem('@ada:travelersInTrip')
        localStorage.removeItem('@ada:searchFlight')
    }

    async function createInvite(data: CreateInvite) {
        try {
            const response = await api.post('/invites', data, {
                headers: { authorization: `Bearer ${token}` },
            })
            return response.data
        } catch (error: any) {
            const e: string = error.response.data.details.pt
            toast.warning(e)
            return undefined
        }
    }

    function logout() {
        setToken(undefined)
        window.location.reload()
        localStorage.removeItem('@ada_admin:token')
        localStorage.removeItem('@ada:token')
    }

    async function loginWithToken(tokenData: string) {
        setToken(tokenData)
        localStorage.setItem('@ada_admin:token', tokenData)
        history.push('/home')
    }

    return (
        <AuthContext.Provider
            value={{
                login,
                token,
                logout,
                createInvite,
                loginWithToken,
                logoutAgent,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export function useAuth(): AuthContext {
    const context = useContext(AuthContext)
    if (!context) {
        throw new Error('useAuth must be used within a Provider')
    }
    return context
}
