/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect } from 'react'
import { Container } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import noUser from '../../assets/no-answares-found.png'
import { Heading } from './styles'
import { useUser } from '../../hooks/use-user'

export function NoUser() {
    const { user } = useUser()
    const history = useHistory()

    useEffect(() => {
        history.push('/home')
    }, [user])

    return (
        <Container>
            <Heading>Selecione um usuário</Heading>
            <img src={noUser} alt="no-users" height={500} width={600} />
        </Container>
    )
}
