/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { ReactNode, useState } from 'react'
import { RiArrowDownSLine } from 'react-icons/ri'
import { MdPerson } from 'react-icons/md'
import { AiFillProfile } from 'react-icons/ai'
import { AgentViewContainer } from './styles'
import { Avatar } from '../../components/Avatar'

import { SearchForUser } from '../SearchForUser'
import { useAuth } from '../../hooks/use-auth'
import { SubTabs } from '../../components/SubTabs'
import { useUser } from '../../hooks/use-user'
import { Button } from '../../components/Button'

interface Props {
    children: ReactNode
}

export function AgentView({ children }: Props) {
    const path = window.location.pathname as string
    const { agent, user, logoutFromUser } = useUser()
    const { logoutAgent } = useAuth()
    const [openHeaderMenu, setOpenHeaderMenu] = useState(false)
    const [subHeaderMenu, setSubHeaderMenu] = useState<string>(path)

    function isUserActive() {
        if (user) {
            return user.companyInfo[0].userIsActive
        }
        return true
    }

    return (
        <AgentViewContainer>
            <header className="agent-header">
                <div className="logo">
                    {/* <img src={logo} alt="Ada" /> */}
                    <h1 className="f22-700-dark">Travel Agent</h1>
                </div>
                {agent && (
                    <div className="header-navigation">
                        <SubTabs
                            direction="HORIZONTAL"
                            changeTab={(e) => setSubHeaderMenu(e)}
                            value={subHeaderMenu}
                            tabs={[
                                {
                                    label: 'Home',
                                    id: '/home',
                                    path: '/home',
                                },
                            ]}
                        />
                    </div>
                )}
                {agent && (
                    <>
                        <div className="menu-user">
                            <div className="user-avatar">
                                <Avatar
                                    label={`${agent.firstName} ${agent.lastName}`}
                                    img={undefined}
                                />
                            </div>
                            <div className="user-info">
                                <h1 className="user-name-label">{`${agent.firstName} ${agent.lastName}`}</h1>
                                <h1 className="user-company-label">
                                    Ada Travel Tech
                                </h1>
                            </div>
                            <button
                                className="dropdown-btn"
                                type="button"
                                onClick={() =>
                                    setOpenHeaderMenu(!openHeaderMenu)
                                }
                            >
                                <RiArrowDownSLine
                                    style={
                                        openHeaderMenu
                                            ? { transform: 'rotate(180deg)' }
                                            : {}
                                    }
                                />
                            </button>
                        </div>
                        {openHeaderMenu && (
                            <>
                                <div className="menu-user-nav">
                                    <button
                                        className="menu-user-nav--btn"
                                        type="button"
                                        onClick={() => logoutAgent()}
                                    >
                                        <AiFillProfile />
                                        <p>Sair</p>
                                    </button>
                                </div>
                                <div
                                    className="menu-user-nav-opaciy"
                                    onClick={() => setOpenHeaderMenu(false)}
                                />
                            </>
                        )}
                    </>
                )}
            </header>
            <div className="body">
                {agent && user && (
                    <div className="left">
                        <div className="search-for-user">
                            <Button
                                color="PRIMARY"
                                text="Deslogar"
                                buttonType="FILLED"
                                onClick={() => logoutFromUser()}
                            />
                        </div>
                    </div>
                )}
                {agent && !user && (
                    <div className="left">
                        <SearchForUser />
                    </div>
                )}
                {!agent && <div />}
                <div className="center">
                    <div className="children">
                        <div
                            className={`children-header ${
                                isUserActive() ? 'bg-gray' : 'bg-red'
                            }`}
                        >
                            <MdPerson />
                            {!agent ? (
                                <h1>Entrar com um agente de viagem</h1>
                            ) : (
                                <h1>{`Visão do viajante ${
                                    !isUserActive() ? '- INATIVO' : ''
                                }`}</h1>
                            )}
                        </div>
                        {children}
                    </div>
                </div>
                {!agent && <div />}
                <div className="right">
                    <div />
                </div>
            </div>
        </AgentViewContainer>
    )
}
